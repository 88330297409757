<template>
    <div class="root">
        <headers></headers>
        <div class="get-lay max-width-all">
            <div class="left">
                <h1>{{ $t('lp.title') }}</h1>
                <p>{{ $t('lp.desc') }}</p>
            </div>
            <img src="../../static/images/banner.png" />
        </div>
        <!-- 质押USDT -->
        <div class="record-title max-width-all">
			<span style="border-bottom:0px;" @click="goV2">
                {{ $t('lp.popupTitle') }} 2.0
            </span>
			<span>
                {{ $t('lp.popupTitle') }} 1.0
            </span>
            <div @click="goRecord(1)">
                <span>{{ $t('lp.record') }}</span>
                <img class="record-img" src="../../static/images/right.png" />
            </div>
        </div>
        <!-- 质押列表 -->
        <div class="lp-list">
            <div>
                <div v-for="(item, index) in usdtList" :key="index">
                    <div class="info">
                        <img src="../../static/images/usdt.png" />
                        <div>
                            <span>{{item.name}}</span>
                            <span>{{ $t('lp.usdtLp') }}</span>
                            <span>{{ $t('lp.lp') }} {{item.day}} {{ $t('lp.day') }}</span>
                        </div>
                    </div>
                    <div class="information">
                        <div class="apr">
                            <span>ARP</span>
                            <span>{{item.apr}}</span>
                        </div>
                        <div class="row">
                            <span>{{ $t('lp.lpYet') }} (USDT)</span>
                            <span><numberGrow :time="15" :type="6" :value="item.stakeAmount" ></numberGrow></span>
                        </div>
                        <div class="row">
                            <span>{{ $t('lp.earn') }} (USDT)</span>
                            <span><numberGrow :time="15" :type="6" :value="item.interestTotal" ></numberGrow></span>
                        </div>
                        <div class="row-lay">
                            <div class="row">
                                <span>{{ $t('lp.extract') }} (USDT)</span>
                                <span><numberGrow :time="15" :type="6" :value="item.interestRemain" ></numberGrow></span>
                            </div>
                            <button v-if="item.interestRemain - 0 > 0" @click="withdrawInterest(item.id)">{{ $t('lp.get') }}</button>
                        </div>
                        <div class="buts">
<!--                            <button @click="toStake(item.id)">{{ $t('lp.lp') }}</button>-->
                            <button @click="goRecord(item.id)" style="color: #ffffff;">{{ $t('lp.cancel') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 为什么选择我们 -->
        <div class="value">
            <div class="title">{{ $t('lp.valueTilte') }}</div>
            <div class="content">
                <div>
                    <img src="../../static/images/control.png" />
                    <span class="name">{{ $t('lp.type1Title') }}</span>
                    <span>{{ $t('lp.type1Desc') }}</span>
                </div>
                <div>
                    <img src="../../static/images/flow.png" />
                    <span class="name">{{ $t('lp.type2Title') }}</span>
                    <span>{{ $t('lp.type2Desc') }}</span>
                </div>
                <div>
                    <img src="../../static/images/apr.png" />
                    <span class="name">{{ $t('lp.type3Title') }}</span>
                    <span>{{ $t('lp.type3Desc') }}</span>
                </div>
                <div>
                    <img src="../../static/images/marketing.png" />
                    <span class="name">{{ $t('lp.type4Title') }}</span>
                    <span>{{ $t('lp.type4Desc') }}</span>
                </div>
            </div>
        </div>
        <!-- 质押弹窗 -->
        <div class="dialog" v-if="showStake">
            <div>
                <div class="title">
                    {{ $t('lp.lp') }}
                    {{selectStakeName}}
                    <img @click="showStake=false" src="../../static/images/close.png" />
                </div>
                <div class="usable">
                    <div>
                        <img src="../../static/images/usdt.png" />
                        USDT
                    </div>
                    <div>
                        <b>{{dealAmount(usdtBalance, 6)}} USDT</b>&nbsp;{{ $t('lp.usable') }}
                    </div>
                </div>
				<div class="usable" style="font-size: 12px;margin: 5px 0px 20px; color: #777777">{{$t('lp.quotaRemain')}} : {{dealAmount(selectStakeQuotaRemain, 6)}}<div class="max" @click="toMax">{{ $t('lp.max') }}</div></div>
                <input placeholder="0.0000" v-model="inputStakeAmount"/>
				<button class="accredit" @click="approveUSDT" v-if="inputStakeAmount && inputStakeAmount - usdtAllowance > 0">{{ $t('lp.accredit') }}</button>
				<button class="disabled" v-else-if="!inputStakeAmount || inputStakeAmount - 0 <= 0 || inputStakeAmount - usdtBalance > 0">{{ $t('lp.lp') }}</button>
				<button @click="stake" v-else>{{ $t('lp.lp') }}</button>

            </div>
        </div>
        <p class="copyright">© 2023 ID LABS. All rights reserved.</p>
    </div>
</template>
<script setup>
    import headers from '@/components/headers'
	import numberGrow from '@/components/numberGanttyNum'

	import {computed, onBeforeUnmount, onMounted, reactive, ref, watch} from 'vue';

	import {memberStore} from "@/store/member"
	import {walletStore} from "@/store/wallet"
	import {webStore} from "@/store/web"
	import {useRouter} from "vue-router";
	import Web3 from "web3";
	import {call, getContractAddress, send} from "@/contract/web3-util";
	import {ElMessage} from "element-plus";
	import i18n from "@/i18n";
	import {end, start} from "@/api/loading";
	import Util from "@/utils/common-util";

	const mStore = memberStore()
	const wStore = walletStore()
	const wbStore = webStore()

	const router = useRouter()

    const goRecord = (type) => {
        router.push({
            name: 'Recordv1',
			query: {type: type}
        })
    }

	const goV2 = () => {
		router.push({
			name: 'Lp'
		})
	}

	let usdtList = reactive([
		{
			id: 1,
			name: 'DEFI AI 0 – USDT',
			apr: '98.55%-219.00%',
			lp: 'USDT',
			earn: 'USDT',
			day: 0,
			stakeAmount: 0,
			interestTotal: 0,
			interestRemain: 0,
			quotaRemain: 0,
			minStake: 0
		},
		{
			id: 2,
			name: 'DEFI AI 30 – USDT',
			apr: '98.55%-292.00%',
			lp: 'USDT',
			earn: 'USDT',
			day: 30,
			stakeAmount: 0,
			interestTotal: 0,
			interestRemain: 0,
			quotaRemain: 0,
			minStake: 0
		},
		{
			id: 3,
			name: 'DEFI AI 90 – USDT',
			apr: '98.55%-365.00%',
			lp: 'USDT',
			earn: 'USDT',
			day: 90,
			stakeAmount: 0,
			interestTotal: 0,
			interestRemain: 0,
			quotaRemain: 0,
			minStake: 0
		}
	])

	const infoInterval = ref(null)
	const usdtBalance = ref(0)
	const usdtAllowance = ref(0)

	const showStake = ref(false)
	const selectStakeType = ref(1)
	const selectStakeName = ref('DEFI AI 0 – USDT')
	const selectStakeQuotaRemain = ref(0)
	const selectStakeMinStake = ref(0)

	const showApr = ref(false);

	const inputStakeAmount = ref('')

	const close = (data) =>{
		if(!data){
			showApr.value = false
		}
	}

	const getWalletUSDTInfo = async() => {
		if (wStore.curAccount) {
			usdtBalance.value = Web3.utils.fromWei(await call('usdt', 'balanceOf', [wStore.curAccount])) - 0
			usdtAllowance.value = Web3.utils.fromWei(await call('usdt', 'allowance', [wStore.curAccount, getContractAddress('stake')])) - 0
		}
	}

	const getStakeInfos = async () => {
		if (wStore.curAccount) {
			const stakeTypes = []
			for (let i = 0; i < usdtList.length; i++) {
				stakeTypes.push(usdtList[i].id)
			}
			const infos = await call('stake', 'getStakeInfos', [wStore.curAccount, stakeTypes])
			for (let i = 0; i < infos.length; i++) {
				const info = infos[i]
				const type = info.stakeType - 0
				const stakeAmount = Web3.utils.fromWei(info.stakeAmount) - 0
				const interestTotal = Web3.utils.fromWei(info.interestTotal) - 0
				const interestRemain = Web3.utils.fromWei(info.interestRemain) - 0
				const quotaRemain = Web3.utils.fromWei(info.quotaRemain) - 0

				const stakeTypeInfos = await call('stake', 'stakeTypeInfos', [type])
				const minStake = Web3.utils.fromWei(stakeTypeInfos.minStake) - 0

				for (let j = 0; j < usdtList.length; j++) {
					if (type == usdtList[j].id) {
						usdtList[j].stakeAmount = stakeAmount
						usdtList[j].interestTotal = interestTotal
						usdtList[j].interestRemain = interestRemain
						usdtList[j].quotaRemain = quotaRemain
						usdtList[j].minStake = minStake
					}
				}
			}
		}
	}

	const approveUSDT = () => {
		if (!wStore.curAccount) {
			ElMessage({
				message: i18n.global.t('alert.connectWallet'),
				type: 'warning'
			})
			return
		}

		start()
		send('usdt', 'approve', [getContractAddress('stake'), '115792089237316195423570985008687907853269984665640564039457584007913129639935'])
			.then((receipt) => {
				if (receipt.status) {
					ElMessage({
						message: i18n.global.t('alert.success'),
						type: 'success'
					})

					usdtAllowance.value = Web3.utils.fromWei('115792089237316195423570985008687907853269984665640564039457584007913129639935', 'ether') - 0
				} else {
					ElMessage({
						message: i18n.global.t('alert.failed'),
						type: 'error'
					})
				}
				end()
			}).catch(function(error) {
			console.error(error)
			end()
		})
	}

	const withdrawInterest = (stakeType) => {
		if (!wStore.curAccount) {
			ElMessage({
				message: i18n.global.t('alert.connectWallet'),
				type: 'warning'
			})
			return
		}

		start()
		send('stake', 'withdrawInterest', [stakeType])
			.then((receipt) => {
				if (receipt.status) {
					ElMessage({
						message: i18n.global.t('alert.success'),
						type: 'success'
					})

					getStakeInfos()
				} else {
					ElMessage({
						message: i18n.global.t('alert.failed'),
						type: 'error'
					})
				}
				end()
			}).catch(function(error) {
			console.error(error)
			end()
		})
	}

	const stake = () => {
		if (!wStore.curAccount) {
			ElMessage({
				message: i18n.global.t('alert.connectWallet'),
				type: 'warning'
			})
			return
		}

		if (mStore.memberInfo.uid - 0 === 0) {
			router.push({path: '/user'})
			ElMessage({
				message: i18n.global.t('alert.notBound'),
				type: 'warning'
			})

			return
		}

		if (!/^((0\.0*[1-9]+[0-9]*)|([1-9]+[0-9]*\.[0-9]*[0-9])|([1-9]+[0-9]*))$/.test(inputStakeAmount.value)) {
			ElMessage({
				message: i18n.global.t('alert.amountError'),
				type: 'warning'
			})

			return
		}

		if (inputStakeAmount.value - 0 < selectStakeMinStake.value) {
			ElMessage({
				message: i18n.global.t('alert.minAmountError') + ' ' + Util.numFormat4Floor(selectStakeMinStake.value) + ' USDT',
				type: 'warning'
			})

			return
		}

		if (inputStakeAmount.value - 0 > selectStakeQuotaRemain.value - 0) {
			ElMessage({
				message: i18n.global.t('alert.quotaInsufficient'),
				type: 'warning'
			})

			return
		}

		if (inputStakeAmount.value - 0 > usdtBalance) {
			ElMessage({
				message: i18n.global.t('alert.insufficient'),
				type: 'warning'
			})

			return
		}

		if (inputStakeAmount.value - 0 > usdtAllowance) {
			return
		}

		start()
		send('stake', 'stake', [selectStakeType.value, Web3.utils.toWei(inputStakeAmount.value + '')]).then(receipt => {
			end()
			if (receipt.status) {
				ElMessage({
					message: i18n.global.t('alert.success'),
					type: 'success'
				})

				inputStakeAmount.value = ''
				showStake.value = false

				getStakeInfos()
			} else {
				ElMessage({
					message: i18n.global.t('alert.failed'),
					type: 'error'
				})
			}
		}).catch(function(error) {
			console.error(error)
			ElMessage({
				message: i18n.global.t('alert.failed'),
				type: 'error'
			})
			end()
		})
	}

	const getStakeInfosTimer = () => {
		getStakeInfos()
		getWalletUSDTInfo()
		clearInterval(infoInterval.val)
		infoInterval.val = setInterval(function () {
			getStakeInfos()
		},20000)
	}

	function dealAmount(amount, decimals) {
		if (decimals && decimals === 6) {
			return Util.numFormat6Floor(amount)
		}
		return Util.numFormat4Floor(amount)
	}

	function toStake(id) {
		if (!wStore.curAccount) {
			ElMessage({
				message: i18n.global.t('alert.connectWallet'),
				type: 'warning'
			})
			return
		}

		inputStakeAmount.value = ''

		for (let i = 0; i < usdtList.length; i++) {
			if (id === usdtList[i].id) {
				getWalletUSDTInfo()

				showStake.value = true
				selectStakeType.value = id
				selectStakeName.value = usdtList[i].name
				selectStakeQuotaRemain.value = usdtList[i].quotaRemain
				selectStakeMinStake.value = usdtList[i].minStake

				showStake.value = true

				break
			}
		}
	}

	function toMax() {
		let maxAmount = selectStakeQuotaRemain.value
		if (maxAmount - usdtBalance.value > 0) {
			maxAmount = usdtBalance.value
		}

		inputStakeAmount.value = maxAmount
	}

	onMounted(() => {
		getStakeInfosTimer()
	})

	onBeforeUnmount(() => {
		clearInterval(infoInterval.val)
	})

	const curAccount = computed(()=>{
		return wStore.curAccount
	})
	watch(curAccount, (newVal, oldVal) =>{
		if (newVal) {
			if (oldVal) {
				alert(i18n.global.t('alert.reLogin'))
			}

			wbStore.accountLogin().then((result) => {
				wbStore.setLoginState(true)
				mStore.queryMemberInfo()

				getStakeInfosTimer()
			}).catch((reason) => {
				wbStore.setLoginState(false)
				console.error('login failed')
			})
		}
	})

</script>
<style scoped>
    .root{
        color: white;
        padding-bottom: 20px;
        background: linear-gradient(90deg, #172929 0%, #0D1113 50%, #0E152B 100%);
    }
    .get-lay{
        width: calc(100% - 320px);
        margin: 100px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .get-lay .left p{
        width: 400px;
        font-size: 12px;
        color: #BECFD2;
    }
    .get-lay img{
        width: 50%;
    }
    .record-title{
        width: calc(100% - 320px);
        margin: 0 auto;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    .record-title img{
        width: 20px;
        transform: rotateY(180deg);
    }
    .record-img{
        transform: rotateY(0) !important;
    }
    .record-title div{
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .record-title > span{
        font-size: 14px;
        font-weight: bold;
        background: linear-gradient(to left, #40FFF4 0%, #FFFFFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        border-bottom: 3px solid #20EEA4;
        padding-bottom: 10px;
    }
    .lp-list{
        width: 100%;
        padding: 100px 0;
        background-image: url(../../static/images/Launching-pad-bg-lp.png);
        background-repeat: no-repeat;
        background-size:  cover;
    }
    .lp-list > div{
        width: calc(100% - 320px);
        max-width: 1200px;
        margin: 0 auto;
        padding: 0;
        display: flex;
        justify-content: space-between;
    }
    .lp-list > div > div{
        width: 30%;
        background-color: #005474;
        border-radius: 24px;
    }
    .lp-list .info{
        display: flex;
        align-items: center;
        padding: 24px
    }
    .lp-list .info img{
        width: 96px;
        margin-right: 16px;
    }
    .lp-list .info div{
        height: 96px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        font-size: 16px;
    }
    .lp-list .info div span:first-child + span{
        color: #20EEA4;
        font-size: 14px;
        white-space: nowrap;
    }
    .lp-list .info div span:last-child{
        color: rgba(255,255,255,0.75);;
        background: rgba(255,255,255,0.15);
        border-radius: 99px 99px 99px 99px;
        text-align: center;
        padding: 5px 20px;
        font-size: 12px;
    }
    .lp-list .information{
        background-color: white;
        border-radius: 24px;
        padding: 24px;
    }
    .lp-list .information .apr{
        background-color: rgba(0,84,116,0.15);
        border-radius: 8px;
        color: #005474;
        padding: 8px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
    }
    .lp-list .information .apr span:first-child{
        font-size: 12px;
        font-weight: normal;
    }
    .lp-list .information .row{
        color: rgba(13,17,19,0.75);
        display: flex;
        flex-direction: column;
        font-size: 12px;
        margin-top: 24px;
    }
    .lp-list .information .row span:last-child{
        font-weight: bold;
        margin-top: 5px;
    }
    .lp-list .information .row-lay{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;
    }
    .lp-list .information .row-lay .row{
        margin-top: 0;
    }
    .lp-list .information .row-lay button{
        font-size: 12px;
        width: 50px;
        height: 25px;
        background-color: #29D8FF !important;
        border-radius: 4px;
        font-weight: bold;
    }
    .lp-list .information .buts{
        display: flex;
        /*justify-content: space-between;*/
        margin-top: 24px;
    }
    .lp-list .information button{
        width: 100%;
        height: 40px;
        background-color: #20EEA4;
        border-radius: 8px;
        font-weight: bold;
    }
    .lp-list .information button:last-child{
        background-color: #005474;
    }
    .value .title{
        width: 100%;
        font-size: 25px;
        text-align: center;
        margin: 100px 0 70px;
        background: linear-gradient(299deg, #20EEA4 0%, #FFFFFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bold;
    }
    .value img{
        width: 80px;
    }
    .value .content {
        max-width: 1082px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .value .content div{
        width: calc(50% - 168px);
        margin-bottom: 30px;
        background-color: #242829;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 16px 74px 32px;
        border-radius: 8px;
        font-size: 12px;
        color: #BECFD2;
    }
    .value .content .name{
        color: #20EEA4;
        font-size: 14px;
        margin: 10px 0;
        text-align: center;
    }
    .list{
        background-color: #1C282C;
        padding: 56px 160px;
    }
    .list .content{
        max-width: calc(1200px - 48px);
        margin: 0 auto 24px;
        padding: 24px;
        border-radius: 12px;
        color: black;
        background-color: #E9FFF7;
        font-size: 12px;
    }
    .list .id{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: rgba(13,17,19,0.5);
    }
    .list .id span:first-child{
        color: #005474;
    }
    .list .buts{
        color: rgba(13,17,19,0.75);
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    .list .buts p:first-child{
        font-weight: bold;
        font-size: 14px;
    }
    .list .buts p:last-child{
        margin-bottom: 0
    }
    .list .buts button{
        width: 120px;
        height: 39px;
        border-radius: 6px;
        margin-top: 10px;
        font-weight: bold;
        background-color: #20EEA4;
    }
    .menu{
        display: flex;
        align-items: center;
        font-size: 12px;
        margin: 0 auto 40px;
    }
    .menu > span{
        margin-right: 20px;
        cursor: pointer;
        padding: 5px 10px;
    }
    .get-menu{
        color: #20EEA4;
        background: rgba(32,238,164,0.1);
        border-radius: 8px 8px 8px 8px;
        border: 1px solid rgba(32,238,164,0.35);
    }
    .dialog{
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, .7);
        position: fixed;
        z-index: 1010;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dialog > div{
        width: calc(100% - 100px);
        max-width: 300px;
        padding: 24px;
        background: #E9FFF7;
        box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.1);
        border-radius: 24px 24px 24px 24px;
        color: black;
        display: flex;
        flex-direction: column;
    }
    .dialog img{
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
    .dialog .title{
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .dialog input{
        height: 40px;
        padding: 0 8px;
        border-radius: 8px;
        border: 1px solid #CCCCCC;
    }
    .dialog button{
        height: 40px;
        font-weight: bold;
        background-color: #20EEA4;
        border-radius: 8px;
        margin-top: 20px;
    }
    .dialog .usable{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 30px 0 10px;
    }
    .dialog .usable div{
        display: flex;
        align-items: center;
        font-size: 12px;
    }
    .dialog .usable div img{
        width: 25px;
        height: 25px;
        margin-right: 5px;
    }
    .dialog .usable .max{
        color: black;
        background-color: #29D8FF;
        padding: 1px 5px;
        border-radius: 4px;
        margin-left: 8px;
		font-size: 12px;
		cursor: pointer;
    }
    .dialog .accredit{
        background-color: #29D8FF;
    }
    .dialog .disabled{
        background-color: #D6D6D6;
		color: #aaaaaa;
    }
    @media screen and (max-width: 900px) {
        .root{
            padding-bottom: 70px
        }
        .lp-list{
            padding: 40px 0;
        }
        .lp-list > div{
            width: 100%;
            flex-direction: column;
        }
        .lp-list > div > div{
            width: calc(100% - 48px);
            margin: 0 auto 40px;
        }
        .lp-list > div > div:last-child{
            margin-bottom: 0;
        }
        .pc{
            display: none;
        }
        .get-lay{
            width: calc(100% - 64px);
            margin: 30px auto 48px;
            flex-direction: column;
            align-items: flex-start;
        }
        .get-lay .left p{
            width: 100%;
            margin-bottom: 20px;
        }
        .get-lay img{
            width: 100%;
        }
        .record-title{
            width: calc(100% - 64px);
        }
        .list{
            padding: 30px 24px;
        }
        .list .buts{
            flex-direction: column;
        }
        .list button{
            width: 100% !important;
            margin-top: 20px !important;
        }
        .menu > span{
            white-space: nowrap;
            margin-right: 5px;
        }
        .value .content div .name{
            margin: 20px 0;
        }
        .value .content div{
            width: calc(100% - 110px);
            padding: 32px;
            margin: 0 auto 24px
        }
        .lp-list .info img{
            width: 80px;
            margin-right: 16px;
        }
        .lp-list .info div{
            height: 80px;
        }
    }
</style>
